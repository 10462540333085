import React, { useEffect, useRef, useState } from 'react'
import { cancelTransactionApi, chargeBuyerApi, getInReviewsLeadList, inReviewLeadApprove } from '../../api/apiHandler';
import $ from "jquery";
import FeatherIcon from "feather-icons-react";
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Swal from 'sweetalert2'
import { renderToStaticMarkup } from 'react-dom/server'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { bodyDecryption, bodyEncryption } from '../common/RedirectPathMange';
import moment from 'moment';
import { Modal } from 'react-bootstrap';

function InReviewView() {
    let { id } = useParams();
    var decId = decodeURIComponent(bodyDecryption(id))
    const navigate = useNavigate();

    const [leadDetails, setLeadDetails] = useState(null)
    const [pageNo, setPageNo] = useState(1);
    const [loader, setLoader] = useState(true);
    const [selectedDisposition, setSelectedDisposition] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [modalImageSrc, setModalImageSrc] = useState("");

    const [currentAudio, setCurrentAudio] = useState(null);
    const audioRef = useRef(null);

    const handlePlay = (audioElement) => {
        if (currentAudio && currentAudio !== audioElement) {
            currentAudio.pause();
        }
        setCurrentAudio(audioElement);
    };

    const typeMapping = {
        'lead_created': 'Lead Created',
        'lead_details_edited': 'Lead Details Edited',
        'user_status_change': 'Status Change',
        'media': 'Media',
        'setter_disposition_status_change': 'Setter Disposition Submitted',
        'closer_disposition_status_change': 'Closer Disposition Submitted',
        'offer_made': 'Offer Made',
        'offer_accepted': 'Offer Accepted',
        'offer_declined': 'Offer Declined',
        'buy_it_now': 'Buy It Now',
        'place_bid': 'Bid placed',        
        // admin side
        'admin_disposition_status_change': 'Disposition Submitted',
        'lead_posted_to_marketplace': 'Lead Posted To Marketplace',
        'admin_note_added': 'Admin Note Added',
        'admin_status_change': 'Admin Status Change',
        'appointment_date': 'Appointment Date',
    };

    useEffect(() => {
        Swal.fire({
            title: 'Please wait...',
            didOpen: () => {
                Swal.showLoading();
            }
        });
        if (id != undefined) {
            getInReviewsLeadList({ "lead_id": decId,"page": pageNo, "record_count": parseInt(process.env.REACT_APP_RECORD_COUNT) }).then((res) => {
                res = JSON.parse(res)
                if (res.code == 200) {
                    Swal.close();
                    setLoader(false);
                    setLeadDetails(res.data.Leads[0])
                    setSelectedDisposition(res.data.Leads[0].disposition);
                } else {
                    Swal.close();
                    setLoader(false);
                    setLeadDetails([])
                }
            });
        }
    }, [id])

    function downloadDocument(url) {
        // Create an anchor element
        const anchor = document.createElement('a');
        // Set the href attribute to the document URL
        anchor.href = url;
        anchor.target = '_blank';
        // Set the download attribute to force download
        anchor.download = url.substring(url.lastIndexOf('/') + 1);
        // Programmatically trigger a click event on the anchor element
        document.body.appendChild(anchor);
        anchor.click();
        // Remove the anchor from the DOM
        document.body.removeChild(anchor);
    }

    function handleImageDownload(image) {
        // const anchor = document.createElement('a');
        // anchor.href = url;
        // anchor.target = '_blank';
        // anchor.download = url.substring(url.lastIndexOf('/') + 1);
        // anchor.style.display = 'none';
        // document.body.appendChild(anchor);
        // anchor.click();
        // document.body.removeChild(anchor);

        let img_url = `${process.env.REACT_APP_AWS_S3_URL}` + `${process.env.REACT_APP_LEAD_IMAGES}` + '/' + image;
        console.log('img_url: ', img_url);
        window.open(img_url, '_blank');
    }

    const handleImageClick = (imageSrc) => {
        setModalImageSrc(imageSrc);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setModalImageSrc("");
    };

    const editLeadDetails = (item) => {
        var encId = encodeURIComponent(bodyEncryption((item).toString()))
        navigate(`/inReviewEdit/${encId}`);
    }

    const showMessage = (msg) => {
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: msg,
            toast: true,
            showConfirmButton: false,
            timer: 1500
        })
    }

    const chargeBuyer = (leadId) => {
        // console.log("lead_id--->",leadId)
        Swal.fire({
            title: 'Are you sure ?',
            text: "You won't be able to revert this!",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#02C9D6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: 'Please wait...',
                    didOpen: () => {
                        Swal.showLoading();
                    }
                });
                chargeBuyerApi({ "lead_id": leadId }).then((response) => {
                    response = JSON.parse(response)
                    if (response.code === 200) {
                        // $('#mangeUser').DataTable().row("#userId" + userId).remove().draw(false);
                        Swal.close();
                        showMessage(response.message);
                        // Update user list after cancel transaction
                        getInReviewsLeadList({ "lead_id": decId,"page": pageNo, "record_count": parseInt(process.env.REACT_APP_RECORD_COUNT) }).then((res) => {
                            res = JSON.parse(res)
                            if (res.code == 200) {
                                setLoader(false);
                                setLeadDetails(res.data.Leads[0])
                                setSelectedDisposition(res.data.Leads[0].disposition);
                            } else {
                                setLoader(false);
                                setLeadDetails([])
                            }
                        });
                    }
                });
            }
        })
    }

    const cancelDetails = (leadId) => {
        // console.log("lead_id--->",leadId)
        Swal.fire({
            title: 'Are you sure you want to cancel transaction?',
            text: "You won't be able to revert this!",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#02C9D6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: 'Please wait...',
                    didOpen: () => {
                        Swal.showLoading();
                    }
                });
                cancelTransactionApi({ "lead_id": leadId }).then((response) => {
                    response = JSON.parse(response)
                    if (response.code === 200) {
                        // $('#mangeUser').DataTable().row("#userId" + userId).remove().draw(false);
                        Swal.close();
                        showMessage(response.message);
                        // Update user list after cancel transaction
                        getInReviewsLeadList({ "lead_id": decId,"page": pageNo, "record_count": parseInt(process.env.REACT_APP_RECORD_COUNT) }).then((res) => {
                            res = JSON.parse(res)
                            if (res.code == 200) {
                                setLoader(false);
                                setLeadDetails(res.data.Leads[0])
                                setSelectedDisposition(res.data.Leads[0].disposition);
                            } else {
                                setLoader(false);
                                setLeadDetails([])
                            }
                        });
                    }
                });
            }
        })
    }

    const viewUserDetails = (item, user_type) => {
        var encId = encodeURIComponent(bodyEncryption((item.id).toString()))
        if(user_type == "setter"){
            navigate(`/setterView/${encId}`);
        } else {
            navigate(`/closerView/${encId}`);
        }
    }

    const approveLead = (leadId) => {
        Swal.fire({
            title: 'Are you sure ?',
            text: "You want to approve this lead !",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#02C9D6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: 'Please wait...',
                    didOpen: () => {
                        Swal.showLoading();
                    }
                });
                inReviewLeadApprove({ "lead_id": leadId }).then((response) => {
                    response = JSON.parse(response)
                    Swal.close();
                    if (response.code === 200) {
                        showMessage(response.message);
                        setTimeout(()=>{
                            navigate("/inReviewList")
                        },1500)
                    }
                });
            }
        })
    }
    
    if (leadDetails == null) return <> </>

    return (
        <>
            <div className='content-page mt-2'>
                <div className='content'>
                    <div className='container-fluid'>
                        <div className='cal'>
                            <div className="row clearfix d-flex justify-content-center text-center" >
                                {loader === false ?
                                <div className="col-lg-9 col-md-12">
                                    <h2>In-Review Lead Details</h2>

                                    <div className="body">
                                        <hr />
                                        <div className="row">
                                            <div className="col-4">
                                                <h5>Industry</h5>                                                
                                                {leadDetails.industry == null ? (
                                                    <p style={{ fontSize: '15px' }}>No data</p>
                                                    ) : (
                                                        <>
                                                        <p style={{ fontSize: '15px' }}>{leadDetails.industry}</p>
                                                    </>
                                                )}

                                               
                                            </div>
                                            <div className="col-4">
                                                <h5>Category</h5>
                                                <p style={{ fontSize: '15px' }}>{leadDetails.category == 'Verified' ? "Approved" : "Pending"}</p>
                                            </div>
                                            <div className="col-4">
                                                <h5>Name</h5>
                                                {leadDetails.name == null ? (
                                                    <p style={{ fontSize: '15px' }}>No data</p>
                                                    ) : (
                                                        <>
                                                        <p style={{ fontSize: '15px' }}>{leadDetails.name}</p>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div className="col-4">
                                                <h5>Email</h5>
                                                {leadDetails.email == null ? (
                                                    <p style={{ fontSize: '15px' }}>No data</p>
                                                    ) : (
                                                        <>
                                                        <p style={{ fontSize: '15px' }}>{leadDetails.email}</p>
                                                    </>
                                                )}
                                            </div>
                                            <div className="col-4">
                                                <h5>Phone Number</h5>
                                                {leadDetails.phone_number == null ? (
                                                    <p style={{ fontSize: '15px' }}>No data</p>
                                                    ) : (
                                                        <>
                                                        <p style={{ fontSize: '15px' }}>{leadDetails.phone_number}</p>
                                                    </>
                                                )}
                                            </div>
                                            <div className="col-4">
                                                <h5>Secondary Phone Number</h5>
                                                {leadDetails.secondary_phone_number ? (
                                                    <p style={{ fontSize: '15px' }}>{leadDetails.secondary_phone_number}</p>
                                                ) : (
                                                    <p style={{ fontSize: '15px' }}>No data</p>
                                                )}
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div className="col-4">
                                                <h5>Is Virtual</h5>
                                                <p style={{ fontSize: '15px' }}>{leadDetails.is_virtual == '0' ? "Not-virtual" : "Virtual"}</p>
                                            </div>
                                            <div className="col-4">
                                                <h5>Appointment Date</h5>
                                                <p style={{ fontSize: '15px' }}>{moment(leadDetails.appointment_date).local().format('YYYY-MM-DD hh:mm A')}</p>
                                            </div>
                                            {/* <div className="col-4">
                                                <h5>Appointment Time</h5>
                                                <p style={{ fontSize: '15px' }}>{leadDetails.appointment_time}</p>
                                            </div> */}
                                            <div className="col-4">
                                                <h5>Lead Status</h5>
                                                {leadDetails.status == null ? (
                                                    <p style={{ fontSize: '15px' }}>No data</p>
                                                    ) : (
                                                    <>
                                                        <p style={{ fontSize: '15px' }}>{leadDetails.status}</p>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div className="col-4">
                                                <h5>Lead Main Status</h5>
                                                {leadDetails.lead_main_status == null ? (
                                                    <p style={{ fontSize: '15px' }}>No data</p>
                                                    ) : (
                                                    <>
                                                        <p style={{ fontSize: '15px' }}>{leadDetails.lead_main_status}</p>
                                                    </>
                                                )}
                                            </div>
                                            <div className="col-4">
                                                <h5>Priority</h5>
                                                {leadDetails.priority ? (
                                                    <p style={{ fontSize: '15px' }}>{leadDetails.priority}</p>
                                                ) : (
                                                    <p style={{ fontSize: '15px' }}>No data</p>
                                                )}
                                            </div>
                                            <div className="col-4">
                                                <h5>Badges</h5>
                                                {leadDetails.badges == null ? (
                                                    <p style={{ fontSize: '15px' }}>No data</p>
                                                    ) : (
                                                        <>
                                                        <p style={{ fontSize: '15px' }}>{leadDetails.badges}</p>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div className="col-4">
                                                <h5>Address</h5>
                                                <p style={{ fontSize: '15px' }}>{leadDetails.address}</p>
                                            </div>
                                            <div className="col-4">
                                                <h5>Avg Rating</h5>
                                                {leadDetails.avg_rating == null ? (
                                                    <p style={{ fontSize: '15px' }}>No data</p>
                                                    ) : (
                                                    <>
                                                        <p style={{ fontSize: '15px' }}>{leadDetails.avg_rating}</p>
                                                    </>
                                                )}
                                            </div>
                                            <div className="col-4">
                                                <h5>Total Review</h5>
                                                {leadDetails.total_review == null ? (
                                                    <p style={{ fontSize: '15px' }}>No data</p>
                                                    ) : (
                                                    <>
                                                        <p style={{ fontSize: '15px' }}>{leadDetails.total_review}</p>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <hr />

                                        <div className="row">
                                            <div className="col-4">
                                                <h5>Show Contact Info</h5>
                                                <p style={{ fontSize: '15px' }}>{leadDetails.is_showcontactinfo == 0 ? "No" : "Yes"}</p>
                                            </div>
                                            <div className="col-4">
                                                <h5>Status</h5>
                                                <p style={{ fontSize: '14px' }}>{leadDetails.is_active == '0' ? "🔴 Inactive" : "🟢 Active"}</p>
                                            </div>
                                            <div className="col-4">
                                                <h5>Created</h5>
                                                <p style={{ fontSize: '15px' }}>{moment(leadDetails.createdAt).local().format('YYYY-MM-DD hh:mm A')}</p>
                                            </div>
                                        </div>
                                        <hr />

                                        <div className="row">
                                            <div className="col-4">
                                                <h5>Stripe ID</h5>
                                                {/* {leadDetails.setter_data == null ? (
                                                    <p style={{ fontSize: '15px' }}>No data</p>
                                                    ) : (
                                                    <>
                                                        <a href={process.env.REACT_APP_STRIPE_BASE_URL + process.env.REACT_APP_STRIPE_PATH_NAME + leadDetails.setter_data.stripe_customer_id} target="_blank" className="text-primary" style={{ fontSize: '15px', cursor: 'pointer', textDecoration: 'underline' }}>{leadDetails.setter_data.stripe_customer_id}</a>
                                                    </>
                                                )} */}
                                                {leadDetails.payment_data == null ? (
                                                    <p style={{ fontSize: '15px' }}>No data</p>
                                                    ) : (
                                                    <>
                                                        <a href={process.env.REACT_APP_STRIPE_BASE_URL + process.env.REACT_APP_STRIPE_TRANSACTION_PATH_NAME + leadDetails.payment_data.stripe_payment_id} target="_blank" className="text-primary" style={{ fontSize: '15px', cursor: 'pointer', textDecoration: 'underline' }}>{leadDetails.payment_data.stripe_payment_id}</a>
                                                    </>
                                                )}
                                            </div>
                                            <div className="col-4">
                                                <h5>Setter</h5>
                                                {leadDetails.setter_data == null ? (
                                                    <p style={{ fontSize: '15px' }}>No data</p>
                                                    ) : (
                                                    <>
                                                        <a target="_blank" className="text-primary" style={{ fontSize: '15px', cursor: 'pointer', textDecoration: 'underline' }} onClick={() => { viewUserDetails(leadDetails.setter_data,"setter") }}>{leadDetails.setter_data.firstname + " " + leadDetails.setter_data.lastname}</a>
                                                    </>
                                                )}
                                            </div>
                                            <div className="col-4">
                                                <h5>Buyer</h5>
                                                {leadDetails.place_bid_data == null ? (
                                                    <p style={{ fontSize: '15px' }}>No data</p>
                                                    ) : (
                                                    <>
                                                        <a target="_blank" className="text-primary" style={{ fontSize: '15px', cursor: 'pointer', textDecoration: 'underline' }} onClick={() => { viewUserDetails(leadDetails.place_bid_data.buyer_user_details,"closer") }}>{leadDetails.place_bid_data.buyer_user_details.firstname + " " + leadDetails.place_bid_data.buyer_user_details.lastname}</a>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <hr />

                                        <div className="row">
                                            <div className="col-4">
                                                <h5>Privacy Consent Box Checked?</h5>
                                                {leadDetails.is_privacy_consent == null ? (
                                                        <p style={{ fontSize: '15px' }}>No data</p>
                                                    ) : (
                                                    <>
                                                        <p style={{ fontSize: '15px' }}>{leadDetails.is_privacy_consent == 1  ? "Yes" : "No"}</p>
                                                    </>
                                                )}
                                            </div>
                                            <div className="col-4">
                                                <h5>Lead Type</h5>
                                                {leadDetails.lead_type == null ? (
                                                        <p style={{ fontSize: '15px' }}>No data</p>
                                                    ) : (
                                                    <>
                                                        <p style={{ fontSize: '15px' }}>{leadDetails.lead_type == "1" ? "Lead" : leadDetails.lead_type == "2" ? "Lead +" : leadDetails.lead_type == "3" ? "Appt" : "Appt +"}</p>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <hr />

                                        <div className="row">
                                            <div className="col-12 text-left">
                                                <h5>Custom Fields</h5>
                                                {leadDetails.lead_field_data.length == 0 ? (
                                                    <p style={{ fontSize: '15px' }}>No data</p>
                                                    ) : (
                                                    <>
                                                        <ul className="list-group">
                                                            {leadDetails.lead_field_data.map((custom_field, index) => (
                                                                <li key={index} className="list-group-item">
                                                                    <div className="note-content">
                                                                        <span className="note-index"><b>{index + 1}.</b></span>
                                                                            <span className="note-text mx-2" style={{ fontWeight: 'bold' }}>{custom_field.field_name}</span>
                                                                            <div className='clearfix'></div>
                                                                            <span className="note-text mx-3">{custom_field.description}</span>
                                                                        </div>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </>
                                                )}
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-12 text-left">
                                                <h5>Lead Title & Description</h5>
                                                {leadDetails.set_bids.listing_title == null ? (
                                                    <p style={{ fontSize: '15px' }}>No data</p>
                                                    ) : (
                                                    <>
                                                        <ul className="list-group">
                                                            <li className="list-group-item">
                                                                <div className="note-content">
                                                                    <span className="note-text mx-2"><b>Title : </b>{leadDetails.set_bids.listing_title}</span>
                                                                    <div className='clearfix'></div>
                                                                    <span className="note-text mx-2"><b>Description : </b>{leadDetails.set_bids.listing_description}</span>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </>
                                                )}
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-12 text-left">
                                                <h5>Lead Notes</h5>
                                                {leadDetails.lead_notes_data.length == 0 ? (
                                                    <p style={{ fontSize: '15px' }}>No data</p>
                                                    ) : (
                                                    <>
                                                        <ul className="list-group">
                                                            {leadDetails.lead_notes_data.map((note, index) => (
                                                                <li key={index} className="list-group-item">
                                                                    <div className="note-content">
                                                                        <span className="note-index"><b>{index + 1}.</b></span>
                                                                            <span className="note-text mx-2" style={{ fontWeight: 'bold' }}>{note.title}</span>
                                                                            <div className='clearfix'></div>
                                                                            <span className="note-text mx-3">{note.notes}</span>
                                                                        </div>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </>
                                                )}
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-12 text-left">
                                                <h5>Lead Images</h5>
                                                {leadDetails.lead_images.length == 0 ? (
                                                    <p style={{ fontSize: '15px' }}>No data</p>
                                                    ) : (
                                                    <>
                                                        <ul className="list-group">
                                                            <div class="row">
                                                                {leadDetails.lead_images.map((image, index) => (
                                                                    <li key={index} className="list-group-item">
                                                                    <div class="col-4 note-content"> 
                                                                        <div style={{ position: 'relative', display: 'inline-block' }}>
                                                                            <img
                                                                                src={image.images ? `${process.env.REACT_APP_AWS_S3_URL}` + `${process.env.REACT_APP_LEAD_IMAGES}` + '/' + image.images : 'https://www.pngitem.com/pimgs/m/508-5087336_person-man-user-account-profile-employee-profile-template.png'}
                                                                                className=""
                                                                                alt="lead-image"
                                                                                style={{ width: 200, height: 200, cursor:'pointer' }}
                                                                                onClick={()=>handleImageClick(`${process.env.REACT_APP_AWS_S3_URL}` + `${process.env.REACT_APP_LEAD_IMAGES}` + '/' + image.images)}
                                                                            />
                                                                            <Tooltip title="Download" placement="top">
                                                                                <IconButton style={{ position: 'absolute', top: 0, right: 0 }} onClick={() => handleImageDownload(image.images)}>
                                                                                    <FeatherIcon icon="download" width="18" color="blue" />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </div>
                                                                    </div>
                                                                    </li>
                                                                ))}
                                                            </div>
                                                        </ul>      
                                                    </>
                                                )}
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-12 text-left">
                                                <h5>Lead Recordings</h5>
                                                {leadDetails.lead_recordings.length === 0 ? (
                                                    <p style={{ fontSize: '15px' }}>No data</p>
                                                ) : (
                                                    <>
                                                        <ul className="list-group">
                                                            {leadDetails.lead_recordings.map((recording, index) => (
                                                                <li key={index} className="list-group-item">
                                                                    <div className="note-content">
                                                                        <div className='row'>
                                                                            <span className="note-index"><b>{index + 1}.</b></span>
                                                                            <audio
                                                                                ref={audioRef}
                                                                                src={`${process.env.REACT_APP_AWS_S3_URL}${process.env.REACT_APP_LEAD_RECORDINGS}/${recording.recordings_name}`}
                                                                                controls
                                                                                onPlay={(e) => handlePlay(e.target)}
                                                                            />
                                                                            <a
                                                                                href={`${process.env.REACT_APP_AWS_S3_URL}${process.env.REACT_APP_LEAD_RECORDINGS}/${recording.recordings_name}`}
                                                                                download
                                                                                className="ml-1 mt-1"
                                                                            >
                                                                                <i className="fas fa-download fa-2x"></i>
                                                                            </a>
                                                                        </div>
                                                                        <div className='row'>
                                                                            <span><strong>{recording.recording_show}:</strong></span>
                                                                            <span className="note-text mx-1">{recording.dummy_recording}</span>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </>
                                                )}
                                            </div>
                                        </div>



                                        {/* <div className="row">
                                            <div className="col-12 text-left">
                                                <h5>Lead Documents</h5> 
                                                {leadDetails.lead_documents.length == 0 ? (
                                                    <p style={{ fontSize: '15px' }}>No data</p>
                                                    ) : (
                                                    <>
                                                        <ul className="list-group">
                                                            {leadDetails.lead_documents.map((document, index) => (
                                                                <li key={index} className="list-group-item">
                                                                    <div className="note-content">
                                                                        <span className="note-index"><b>{index + 1}.</b></span>
                                                                        <span> <strong>{document.document_show + " : "}</strong> </span>
                                                                        <a href={`${process.env.REACT_APP_AWS_S3_URL}` + `${process.env.REACT_APP_LEAD_DOCUMENTS}` + '/' + document.documents} target='_blank' className="note-text mx-2">{document.dummy_documents}</a>
                                                                        <Tooltip title="Download">
                                                                            <IconButton onClick={() => downloadDocument(document.documents)}>
                                                                                <a href={document.documents} target='_blank' download>
                                                                                    <FeatherIcon icon="download" width="18" color="blue" />
                                                                                </a>
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </div>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                        </>
                                                    )}
                                            </div>
                                        </div>      */}
                                        <br /><br />
                                    </div>

                                    <h2>  Lead History </h2>

                                    <div className="row">
                                        <div className="col-12 text-left">
                                            {leadDetails.lead_history_log.length == 0 ? (
                                                <p style={{ fontSize: '15px' }}>No data</p>
                                                ) : (
                                                <>
                                                    <ul className="list-group">
                                                        {leadDetails.lead_history_log.map((historyLog, index) => (
                                                            <li key={index} className="list-group-item">

                                                                <div className='row'>
                                                                    <span><b>{ moment(historyLog.createdAt).local().format('YYYY-MM-DD hh:mm A') }</b></span>
                                                                </div>


                                                                <div className='row'>
                                                                    <ul className='no-bullets'>
                                                                        <li>
                                                                            <span><b>{typeMapping[historyLog.type] || historyLog.type}</b></span>
                                                                        </li>
                                                                        { historyLog.type === 'media' ?
                                                                        <li>
                                                                             <span>New Media Added</span> 
                                                                        </li>    : "" }
                                                                    </ul>
                                                                </div>

                                                                { historyLog.price !== null ? 
                                                                    <div className='row'>
                                                                        <span> Price : ${historyLog.price}</span>
                                                                    </div>
                                                                : "" }
                                                                
                                                                { historyLog.custom_field !== null ? 
                                                                    <div className='row'>
                                                                        <span> Custom Field : {historyLog.custom_field}</span>
                                                                    </div>
                                                                : "" }

                                                                { historyLog.old_status !== null ? 
                                                                    <div className='row'>
                                                                        <span> Old Status : {historyLog.old_status}</span>
                                                                    </div> 
                                                                : "" }

                                                                { historyLog.new_status !== null ? 
                                                                    <div className='row'>
                                                                        <span> New Status : {historyLog.new_status}</span>
                                                                    </div>
                                                                : "" }

                                                                { historyLog.note !== null ? 
                                                                    <div className='row'>
                                                                        <span> Note : {historyLog.note}</span>
                                                                    </div>
                                                                : "" }

                                                                {historyLog.lead_history_dis_images && historyLog.lead_history_dis_images.length > 0 ? (
                                                                    <div className='row'>
                                                                        <ul className='no-bullets'>
                                                                        <span> Evidence </span>
                                                                        {historyLog.lead_history_dis_images.map((evidence, index) => (
                                                                            <div key={index}>
                                                                                <li>
                                                                                    {/* <span>{evidence.history_dis_images.split('/')[evidence.history_dis_images.split('/').length - 1]}</span> */}
                                                                                    {/* <img src={evidence.history_dis_images} alt={`Evidence ${index}`} style={{ width: '100px', height: '100px' }} /> */}
                                                                                    {/* <a href={evidence.history_dis_images} target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}>
                                                                                        {evidence.history_dis_images.split('/')[evidence.history_dis_images.split('/').length - 1]}
                                                                                    </a> */}
                                                                                    <a href={evidence.history_dis_images} target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}>
                                                                                        {"Evidence " + (index + 1)}
                                                                                    </a>
                                                                                </li>
                                                                            </div>
                                                                        ))}
                                                                        </ul>
                                                                    </div>
                                                                ) : ""}

                                                                {historyLog.data_change && historyLog.data_change.length > 0 ? (
                                                                    <>
                                                                    {/* <div className='row'> */}
                                                                        {historyLog.data_change.map((change, index) => (
                                                                            <div key={index}>
                                                                                <ul className='no-bullets mt-2'>
                                                                                    <li>
                                                                                        <strong><span>Title: {change.title}</span></strong>
                                                                                    </li>
                                                                                    <li>
                                                                                        <span>Old Data: {change.title === 'appointment_date' ? moment(change.oldData).local().format('YYYY-MM-DD hh:mm A') : change.oldData}</span>
                                                                                    </li>
                                                                                    <li>
                                                                                        <span>New Data: {change.title === 'appointment_date' ? moment(change.newData).local().format('YYYY-MM-DD hh:mm A') : change.newData}</span>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        ))}
                                                                    {/* </div> */}
                                                                    </>
                                                                ) : ""}


                                                                {/* <div className='row'>
                                                                    <span>{historyLog.id}</span>
                                                                </div> */}
                                                            </li>
                                                        ))}
                                                    </ul>      
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    
                                    <Link to='/inReviewList' className="btn btn-primary btn-lg common_hover mx-1">
                                        <i className="mdi mdi-chevron-left"></i> <span> Back </span>
                                    </Link>

                                    <button className="btn btn-info btn-lg mx-1" onClick={() => { editLeadDetails(leadDetails.id) }}>
                                        <i className=""></i> <span> Edit </span>
                                    </button>

                                    {leadDetails.is_in_review_approve == 0 && (
                                        <Link style={{backgroundColor:"green", color:"white"}} className="btn btn-lg common_hover mx-2" onClick={() => { approveLead(leadDetails.id) }}>
                                            <i className=""></i> <span> Approve </span>
                                        </Link>
                                    )}

                                    {/* {leadDetails.is_paid == 1 && (
                                        <>   
                                        <Link style={{backgroundColor:"green", color:"white"}} className="btn btn-lg common_hover mx-1" onClick={() => { chargeBuyer(leadDetails.id) }} >
                                            <i className=""></i> <span> Charge Buyer </span>
                                        </Link>

                                        <button style={{backgroundColor:"red", color:"white"}} className="btn btn-lg mx-1" onClick={() => { cancelDetails(leadDetails.id) }} >
                                            <i className=""></i> <span> Cancel Transaction </span>
                                        </button>
                                        </>
                                    )} */}

                                </div>
                                :''}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Body>
                    <img src={modalImageSrc} alt="Full Screen" className="img-fluid" />
                </Modal.Body>
            </Modal>
        </>
    );
}

export default InReviewView;