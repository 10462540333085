import { axiosClient } from "./apiClient";

export function login(data) {
    return axiosClient.post('admin/login', data);
}

export function logout(data) {
    return axiosClient.get('admin/logout', data);
}

export function changePassword(data) {
    return axiosClient.post('admin/change-password', data);
}

export function getAdmin(data) {
    return axiosClient.get('admin/get-profile', data);
}

export function editProfile(data) {
    return axiosClient.post('admin/update-profile', data);
}

export function getManagePage(data) {
    return axiosClient.post('admin/page-contents-list', data);
}

export function getStaticPageData(data) {
    return axiosClient.post('admin/get-static-page-data', data);
}

export function editManagePage(data) {
    return axiosClient.post('admin/page-contents-add-update', data);
}

export function getUsersList(data) {
    return axiosClient.post('admin/users-list', data);
}

export function getSettersList(data) {
    return axiosClient.post('admin/setter-list', data);
}

export function getClosersList(data) {
    return axiosClient.post('admin/closer-list', data);
}

export function activeInUser(data) {
    return axiosClient.post('admin/active-in-user', data);
}

export function deleteUser(data) {
    return axiosClient.post('admin/delete-user', data);
}

export function usersFeedbackList(data) {
    return axiosClient.post('admin/users-feedback-list', data);
}

export function addIndustry(data) {
    return axiosClient.post('admin/add-industry', data);
}

export function editIndustry(data) {
    return axiosClient.post('admin/edit-industry', data);
}

export function deleteIndustry(data) {
    return axiosClient.post('admin/delete-industry', data);
}

export function getIndustrylist(data) {
    return axiosClient.post('admin/list-industry', data);
}

export function activeInIndustry(data) {
    return axiosClient.post('admin/active-in-industry', data);
}

export function addFaq(data) {
    return axiosClient.post('admin/add-faq', data);
}

export function editFaq(data) {
    return axiosClient.post('admin/edit-faq', data);
}

export function deleteFaq(data) {
    return axiosClient.post('admin/delete-faq', data);
}

export function getFaqListing(data) {
    return axiosClient.post('admin/list-faq', data);
}

export function changeFaqStatus(data) {
    return axiosClient.post('admin/active-in-faq', data);
}

export function getLeadList(data) {
    return axiosClient.post('admin/list-leads', data);
}

export function getToBeVerifiedLeadList(data) {
    return axiosClient.post('admin/to-be-verified-list', data);
}

export function getInReviewsLeadList(data) {
    return axiosClient.post('admin/in-review-leads', data);
}

export function getUserLeadList(data) {
    return axiosClient.post('admin/user-lead-list', data);
}

export function deleteLead(data) {
    return axiosClient.post('admin/delete-lead', data);
}

export function changeLeadStatus(data) {
    return axiosClient.post('admin/active-in-lead', data);
}

export function editLead(data) {
    return axiosClient.post('admin/edit-lead', data);
}

export function editLeadNote(data) {
    return axiosClient.post('admin/edit-lead-note', data);
}

export function DeleteLeadNote(data) {
    return axiosClient.post('admin/delete-lead-note', data);
}

export function editLeadRecording(data) {
    return axiosClient.post('admin/edit-lead-recording', data);
}

export function DeleteLeadRecording(data) {
    return axiosClient.post('admin/delete-lead-recording', data);
}

export function DeleteLeadDocument(data) {
    return axiosClient.post('admin/delete-lead-document', data);
}

export function DeleteLeadImages(data) {
    return axiosClient.post('admin/delete-lead-image', data);
}

export function editLeadDocument(data) {
    return axiosClient.post('admin/edit-lead-document', data);
}

export function addLeadImages(data) {
    return axiosClient.post('admin/add-lead-images', data);
}

export function approveRejectLead(data) {
    return axiosClient.post('admin/approve-reject-lead', data);
}


// export function dashboard(data) {
//     return axiosClient.post('authentication/dashBoard', data);
// }

// Tutorial Module

export function getCategoryList(data) {
    return axiosClient.post('admin/get-category-list', data);
}

export function addEditCategory(data) {
    return axiosClient.post('admin/add-edit-category', data);
}

export function deleteCategory(data) {
    return axiosClient.post('admin/delete-category', data);
}

export function addEditTutorial(data) {
    return axiosClient.post('admin/add-edit-tutorial', data);
}

export function deleteTutorial(data) {
    return axiosClient.post('admin/delete-tutorial', data);
}

export function activeInactiveTutorial(data) {
    return axiosClient.post('admin/active-inactive-tutorial', data);
}

export function getCloserSetterTutorialList(data) {
    return axiosClient.post('admin/get-closer-setter-tutorial-list', data);
}

// Lead Question Module

export function getQuestionList(data) {
    return axiosClient.post('admin/get-lead-question-list', data);
}

// Lead Review & Rating Module

export function getRatingReviewList(data) {
    return axiosClient.post('admin/get-rating-review-list', data);
}

export function approveRejectReviews(data) {
    return axiosClient.post('admin/approve-reject-reviews', data);
}

export function chargeBuyerApi(data) {
    return axiosClient.post('admin/charge-buyer-admin', data);
}

export function cancelTransactionApi(data) {
    return axiosClient.post('admin/cancel-transaction-admin', data);
}

export function getEarningList(data) {
    return axiosClient.post('admin/earning-amount-list', data);
}

export function getCountList(data) {
    return axiosClient.post('admin/total-counts', data);
}

export function getCommissionList(data) {
    return axiosClient.post('admin/get-global-commission', data);
}

export function updateGlobalCommision(data) {
    return axiosClient.post('admin/global-commission', data);
}

export function getAllLeadData(data) {
    return axiosClient.post('admin/get-all-lead-data', data);
}

export function adminChatNotificationStore(data) {
    return axiosClient.post('admin/admin-chat-notification', data);
}

export function addRecentSoldLead(data) {
    return axiosClient.post('admin/recent-sold-lead', data);
}

export function addUserAgreement(data) {
    return axiosClient.post('admin/add-user-agreement', data);
}

export function getUserLeadsRatingReviewList(data) {
    return axiosClient.post('admin/get-user-lead-reviews-list', data);
}

export function inReviewLeadApprove(data) {
    return axiosClient.post('admin/in-review-approve', data);
}

export function bulkUploadLeads(data) {
    return axiosClient.post('admin/bulk-upload-leads', data);
}