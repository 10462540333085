import React, { useEffect } from 'react'
import { Link, useLocation } from "react-router-dom";
import '../sidebar/Sidebar.css';
import Collapse from 'react-bootstrap/Collapse';
import { useState } from 'react';
import { collection, getDocs, doc, getDoc, updateDoc, onSnapshot } from "firebase/firestore"; 
import { db } from '../../config/firebase';
import { useChatCount } from '../../pages/context/total_count';
import Swal from 'sweetalert2';
import { getCountList } from '../../api/apiHandler';

function Sidebar({ isSidebarOpen }) {

    const location = useLocation();
    // const [open, setOpen] = useState(false);
    const [activeLink, setActiveLink] = useState(0);
    const [activeLinkTask, setActiveLinkTask] = useState(0);
    const [activeLink1, setActiveLink1] = useState(0);
    const [activeLinkSupport, setActiveLinkSupport] = useState(0);
    const [activeLinkTutorials, setActiveLinkTutorials] = useState(0);
    const [activeLinkSetting, setActiveLinkSetting] = useState(0);
    // const [totalChatCount, setTotalChatCount] = useState(0);

    const { totalChatCount, setTotalChatCount, questionCount, setQuestionCount, toBeVerifiedCount, setToBeVerifiedCount, inReviewCount, setInReviewCount } = useChatCount();

    // const handleSetActiveLink = (link) => {
    //     setActiveLink(1);
    // };

    const [pageNo, setPageNo] = useState(1);

    let chatQuestionCount = totalChatCount + questionCount;  
    let toBEVerifiedInReviewCount = toBeVerifiedCount + inReviewCount;
    

    useEffect(() => {
        if(location.pathname == "/settersList" || location.pathname == "/closersList" || location.pathname.match('/closerView') ||  location.pathname.match('/setterView')){
            setActiveLink(1);
        }
        if(location.pathname == "/toBeVerifiedList" || location.pathname == "/inReviewList" || location.pathname.match('/toBeVerifiedView') ||  location.pathname.match('/inReviewView') ||  location.pathname.match('/toBeVerifiedEdit') ||  location.pathname.match('/inReviewEdit') ){
            setActiveLinkTask(1);
        }
        if(location.pathname == "/about" || location.pathname == "/useragreement" || location.pathname == "/policy" || location.pathname == "/terms" || location.pathname == "/faqList"){
            setActiveLink1(1);
        }
        if(location.pathname == "/chatuserlist"){
            setActiveLinkSupport(1);
        }
        if(location.pathname == "/category" || location.pathname == "/closertutorial" || location.pathname == "/settertutorial"){
            setActiveLinkTutorials(1);
        }
        if(location.pathname == "/commission"){
            setActiveLinkSetting(1);
        }
    }, [location.pathname]);

    useEffect(() => {if(sessionStorage.getItem('flag')){
        // console.log("sdjkcsdjcsdjlk")
            setActiveLink(0);
            setActiveLinkTask(0);
            setActiveLink1(0);
            setActiveLinkSupport(0);
            setActiveLinkTutorials(0);
            setActiveLinkSetting(0);
            sessionStorage.clear();
    }
    })

    useEffect(async ()=>{
       await calculateTotalChatCount();
       await fetchTotalCount();
    },[])


    const calculateTotalChatCount = async () => {
        try {
            const recentChatCollection = collection(db, process.env.REACT_APP_FIREBASE_TABLE_RECENT_CHAT);
    
            onSnapshot(recentChatCollection, (snapshot) => {
                let totalCount = 0;
                snapshot.forEach(doc => {
                    const data = doc.data();
                    if (data.receiver_id === "A1") {
                        totalCount += parseInt(data.chat_count, 10) || 0;
                    }
                });
    
                setTotalChatCount(totalCount);
            });
        } catch (error) {
            console.log('Error calculating total chat count: ', error);
            // Swal.fire({
            //     position: 'top-end',
            //     icon: 'error',
            //     title: error.message,
            //     toast: true,
            //     showConfirmButton: false,
            //     timer: 2000
            // });
        }
    };

    const fetchTotalCount = async () => {
        try {
            const res = await getCountList({ page: pageNo, record_count: parseInt(process.env.REACT_APP_RECORD_COUNT) });
            const parsedRes = JSON.parse(res);
            if (parsedRes.code === 200) {
                setQuestionCount(parsedRes.data.totalLeadSpecificCount);
                setToBeVerifiedCount(parsedRes.data.totalToBeVerifiedCount);
                setInReviewCount(parsedRes.data.totalInReviewCount);
            } else {
                console.log("Question count error....");
            }
        } catch (error) {
            console.error('Error fetching count list:', error);
        }
    };

    const handleCount = async () => {
        await calculateTotalChatCount()
        await fetchTotalCount()
    }

    return (
        <div className={`left side-menu sidebar ${isSidebarOpen ? 'open' : 'closed'}`}>

            <div className="slimscroll-menu" id="remove-scroll" >

                {/* <div id="sidebar-menu">
                    <ul className="metismenu" id="side-menu">
                        <li>
                            <Link to='/' className={`${location.pathname === "/" ? "waves-effect active" : ""}`}>
                                <i className="mdi mdi-home"></i> <span> Dashboard </span>
                            </Link>
                        </li>
                        <li>
                            <Link to='/usersList' className={`${location.pathname === "/usersList" ? "waves-effect active" : ""}`}>
                                <i className="mdi mdi-account"></i> <span> Users </span>
                            </Link>
                        </li>

                        <li>
                            <Link role="button" onClick={() => { setOpen(!open); }} className={open ? "waves-effect active" : ""}>
                                <i className="mdi mdi-file"></i> <span> Pages </span>
                            </Link>

                            {open ?
                                <ul className="submenu">
                                    <li>
                                        <Link to='/about' className={`${location.pathname === "/about" ? "waves-effect active" : ""}`}>
                                            <i className="mdi mdi-file"></i> <span> About Us </span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to='/policy' className={`${location.pathname === "/policy" ? "waves-effect active" : ""}`}>
                                            <i className="mdi mdi-file"></i> <span> Privacy Policy </span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to='/terms' className={`${location.pathname === "/terms" ? "waves-effect active" : ""}`}>
                                            <i className="mdi mdi-file"></i> <span> Terms & Conditions </span>
                                        </Link>
                                    </li>
                                </ul> : false
                            }
                        </li>

                    </ul>
                </div> */}

                <div id="sidebar-menu" onClick={()=>handleCount()}>
                    <ul className="metismenu" id="side-menu">
                        <li>
                            <Link to='/' className={`${location.pathname === "/" ? "waves-effect active" : "waves-effect"}`} onClick={()=>{setActiveLink(0);setActiveLinkTask(0);setActiveLink1(0);setActiveLinkSupport(0);setActiveLinkTutorials(0);setActiveLinkSetting(0);}} >
                                <i className="mdi mdi-home"></i> <span> Dashboard </span>
                            </Link>
                        </li>
                        {/* <li>
                        <Link to='/usersList' className={`${location.pathname === "/usersList" ? "waves-effect active" : "waves-effect"}`} onClick={() => handleSetActiveLink('/usersList')}>
                            <i className="mdi mdi-account"></i> <span> Users </span>
                        </Link>
                        </li> */}
                        <li>
                            {location.pathname.match('/airexport') || location.pathname.match('/airexportdetail') ? "mm-active" : ""}

                            <Link role="button" className={location.pathname.match('/settersList') || location.pathname.match('/closersList') ||  location.pathname.match('/closerView') ||  location.pathname.match('/setterView') ? "waves-effect active" : "waves-effect"} onClick={() => {setActiveLink( activeLink == 1 ? 0 : 1);setActiveLinkTask(0);setActiveLink1(0);setActiveLinkSupport(0);setActiveLinkTutorials(0);setActiveLinkSetting(0);}}>
                            {/* <Link role="button" className={`${location.pathname == "/settersList" || location.pathname == "/closersList" ? "waves-effect active" : "waves-effect"}`} onClick={() => setActiveLink( activeLink == 1 ? 0 : 1)}> */}
                                <i className="mdi  mdi-account"></i> <span> Users </span>
                            </Link>

                            {activeLink == 1 &&
                                <ul className="submenu">
                                <li>
                                    <Link to='/settersList' className={location.pathname.match('/settersList') ||  location.pathname.match('/setterView')? "waves-effect subMenuActive" : "waves-effect"} >
                                        <i className="mdi mdi-account"></i> <span> Setters </span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/closersList' className={location.pathname.match('/closersList') ||  location.pathname.match('/closerView')? "waves-effect subMenuActive" : "waves-effect"} >
                                        <i className="mdi mdi-account"></i> <span> Closers </span>
                                    </Link>
                                </li>
                                </ul>
                            }
                        </li>

                        <li>
                            {location.pathname.match('/airexport') || location.pathname.match('/airexportdetail') ? "mm-active" : ""}

                            <Link role="button" className={location.pathname.match('/toBeVerifiedList') || location.pathname.match('/inReviewList') ||  location.pathname.match('/toBeVerifiedView') ||  location.pathname.match('/inReviewView') ||  location.pathname.match('/toBeVerifiedEdit') ||  location.pathname.match('/inReviewEdit') ? "waves-effect active" : "waves-effect"} onClick={() => {setActiveLink(0);setActiveLinkTask(activeLinkTask == 1 ? 0 : 1);setActiveLink1(0);setActiveLinkSupport(0);setActiveLinkTutorials(0);setActiveLinkSetting(0);}}>
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="d-flex align-items-center">
                                        <i className="mdi  mdi-account"></i> 
                                        <span> Task Lists </span>
                                    </div>
                                    {/* here i want to sum of chat count and Lead Specific Question count  */}
                                    {toBEVerifiedInReviewCount != 0 && (<span className={location.pathname.match('/toBeVerifiedList') || location.pathname.match('/inReviewList') ? "countfocus" : "count"}>
                                        {toBEVerifiedInReviewCount}
                                    </span>)}
                                </div>
                            </Link>

                            {activeLinkTask == 1 &&
                                <ul className="submenu">
                                <li>
                                    <Link to='/toBeVerifiedList' className={location.pathname.match('/toBeVerifiedList') ||  location.pathname.match('/toBeVerifiedView') ||  location.pathname.match('/toBeVerifiedEdit') ? "waves-effect subMenuActive" : "waves-effect"} >
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="d-flex align-items-center">
                                                <i className="mdi mdi-account"></i> 
                                                <span> To Be Verified </span>
                                            </div>
                                            {toBeVerifiedCount != 0 && (<span className={location.pathname.match('/toBeVerifiedList') ? "countfocus" : "count"}>
                                                {toBeVerifiedCount}
                                            </span>)}
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/inReviewList' className={location.pathname.match('/inReviewList') ||  location.pathname.match('/inReviewView') ||  location.pathname.match('/inReviewEdit') ? "waves-effect subMenuActive" : "waves-effect"} >
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="d-flex align-items-center">
                                                <i className="mdi mdi-account"></i> 
                                                <span> In Review </span>
                                            </div>
                                            {inReviewCount != 0 && (<span className={location.pathname.match('/toBeVerifiedList') ? "countfocus" : "count"}>
                                                {inReviewCount}
                                            </span>)}
                                        </div>
                                    </Link>
                                </li>
                                </ul>
                            }
                        </li>

                        <li>
                        <Link role="button" className={`${location.pathname == "/about" || location.pathname == "/useragreement" || location.pathname == "/policy" || location.pathname == "/terms" || location.pathname == "/faqList" ? "waves-effect active" : "waves-effect"}`} onClick={() => {setActiveLink1(activeLink1 == 1 ? 0 : 1);setActiveLinkTask(0);setActiveLink(0);setActiveLinkSupport(0);setActiveLinkTutorials(0);setActiveLinkSetting(0);}} >
                            <i className="mdi mdi-file"></i> <span> Pages </span>
                        </Link>

                        {activeLink1 == 1 &&
                            <ul className="submenu">
                            <li>
                                <Link to='/about' className={`${location.pathname === "/about" ? "waves-effect subMenuActive" : "waves-effect"}`}>
                                <i className="mdi mdi-file"></i> <span> About Us </span>
                                </Link>
                            </li>
                            <li>
                                <Link to='/useragreement' className={`${location.pathname === "/useragreement" ? "waves-effect subMenuActive" : "waves-effect"}`}>
                                <i className="mdi mdi-file"></i> <span> User Agreement </span>
                                </Link>
                            </li>
                            <li>
                                <Link to='/policy' className={`${location.pathname === "/policy" ? "waves-effect subMenuActive" : "waves-effect"}`}>
                                <i className="mdi mdi-file"></i> <span> Privacy Policy </span>
                                </Link>
                            </li>
                            <li>
                                <Link to='/terms' className={`${location.pathname === "/terms" ? "waves-effect subMenuActive" : "waves-effect"}`}>
                                <i className="mdi mdi-file"></i> <span> Terms & Conditions </span>
                                </Link>
                            </li>
                            <li>
                                <Link to='/faqList' className={`${location.pathname === "/faqList" ? "waves-effect subMenuActive" : "waves-effect"}`}>
                                <i className="mdi mdi-file"></i> <span> FAQ's </span>
                                </Link>
                            </li>
                            </ul>
                        }
                        </li>

                        <li>
                            <Link to='/industrailList' className={`${location.pathname.match('/industrailList') ||  location.pathname.match('/view-industrail') || location.pathname.match('/edit-industrail') ? "waves-effect active" : "waves-effect"}`} onClick={()=>{setActiveLink(0);setActiveLinkTask(0);setActiveLink1(0);setActiveLinkSupport(0);setActiveLinkTutorials(0);setActiveLinkSetting(0);}}>
                            <i className='mdi mdi-factory'></i> <span> Industries </span>
                            </Link>
                        </li>
                        <li>
                            <Link to='/leadsList' className={`${location.pathname.match('/leadsList') ||  location.pathname.match('/leadView') || location.pathname.match('/leadEdit')? "waves-effect subMenuActive" : "waves-effect"}`} onClick={()=>{setActiveLink(0);setActiveLinkTask(0);setActiveLink1(0);setActiveLinkSupport(0);setActiveLinkTutorials(0);setActiveLinkSetting(0);}}>
                            <i className='mdi  mdi-file-presentation-box'></i> <span> Leads </span>
                            </Link>
                        </li>
                        <li>
                            <Link to='/add-recent-sold-lead' className={`${location.pathname.match('/add-recent-sold-lead') ? "waves-effect subMenuActive" : "waves-effect"}`} onClick={()=>{setActiveLink(0);setActiveLinkTask(0);setActiveLink1(0);setActiveLinkSupport(0);setActiveLinkTutorials(0);setActiveLinkSetting(0);}}>
                            <i className='mdi  mdi-file-presentation-box'></i> <span> Recently Sold </span>
                            </Link>
                        </li>
                        {/* <li>
                            <Link to='/bulk-upload-leads' className={`${location.pathname.match('/bulk-upload-leads') ? "waves-effect subMenuActive" : "waves-effect"}`} onClick={()=>{setActiveLink(0);setActiveLinkTask(0);setActiveLink1(0);setActiveLinkSupport(0);setActiveLinkTutorials(0);setActiveLinkSetting(0);}}>
                            <i className='mdi  mdi-file-presentation-box'></i> <span> Bulk Upload </span>
                            </Link>
                        </li> */}
                        <li>
                            {location.pathname.match('/airexport') || location.pathname.match('/airexportdetail') ? "mm-active" : ""}

                            <Link role="button" className={location.pathname.match('/chatuserlist') || location.pathname.match('/chat') || location.pathname.match('/lead-question') ? "waves-effect active" : "waves-effect"} onClick={() => {setActiveLink(0);setActiveLinkTask(0);setActiveLink1(0);setActiveLinkSupport(activeLinkSupport ==  1 ? 0 : 1);setActiveLinkTutorials(0);setActiveLinkSetting(0);}}>
                            {/* <Link role="button" className={`${location.pathname == "/settersList" || location.pathname == "/closersList" ? "waves-effect active" : "waves-effect"}`} onClick={() => setActiveLink( activeLink == 1 ? 0 : 1)}> */}
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="d-flex align-items-center">
                                        <i className="fa-solid fa-circle-info"></i> 
                                        <span className="ml-2"> User Support</span>
                                    </div>
                                    {/* here i want to sum of chat count and Lead Specific Question count  */}
                                    {chatQuestionCount != 0 && (<span className={location.pathname.match('/chatuserlist') || location.pathname.match('/chat') || location.pathname.match('/lead-question') ? "countfocus" : "count"}>
                                        {chatQuestionCount}
                                    </span>)}
                                </div>
                            </Link>

                            {activeLinkSupport == 1 &&
                                <ul className="submenu">
                                <li>
                                    {/* <Link to='/chatuserlist' className={location.pathname.match('/chatuserlist') ? "waves-effect subMenuActive" : "waves-effect"} >
                                        <div className="justify-content-between d-flex">
                                        <i className="fa-solid fa-comment"></i>
                                            <span> Chat </span>
                                            <span className="d-flex justify-content-center align-items-center bg-white text-dark rounded-circle" style={{ width: '30px', height: '30px' }}>
                                                {totalChatCount}
                                            </span>
                                        </div>
                                    </Link> */}
                                    <Link to='/chatuserlist' className={location.pathname.match('/chatuserlist') || location.pathname.match('/chat') ? "waves-effect subMenuActive" : "waves-effect"} >
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="d-flex align-items-center">
                                                <i className="fa-solid fa-comment"></i>
                                                <span className="ml-2"> Chat </span>
                                            </div>
                                            {totalChatCount != 0 && (<span className={location.pathname.match('/chatuserlist') ? "countfocus" : "count"}>
                                                {totalChatCount}
                                            </span>)}
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/lead-question' className={location.pathname.match('/lead-question') ? "waves-effect subMenuActive" : "waves-effect"} >
                                        {/* <i class="fa-solid fa-person-circle-question"></i> <span> Lead Specific Question </span> */}
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="d-flex align-items-center">
                                                <i className="fa-solid fa-person-circle-question"></i> 
                                                <span className="ml-2"> Lead Specific Question </span>
                                            </div>
                                            {questionCount != 0 && (<span className={location.pathname.match('/lead-question') ? "countfocus" : "count"}>
                                                {questionCount}
                                            </span>)}
                                        </div>
                                    </Link>
                                </li>
                                </ul>
                            }
                        </li>

                        <li>
                            {location.pathname.match('/airexport') || location.pathname.match('/airexportdetail') ? "mm-active" : ""}

                            <Link role="button" className={location.pathname.match('/category') || location.pathname.match('/closertutorial') ||  location.pathname.match('/settertutorial') ? "waves-effect active" : "waves-effect"} onClick={() => {setActiveLink(0);setActiveLinkTask(0);setActiveLink1(0);setActiveLinkSupport(0);setActiveLinkTutorials(activeLinkTutorials == 1 ? 0 : 1);setActiveLinkSetting(0);}}>
                            {/* <Link role="button" className={`${location.pathname == "/settersList" || location.pathname == "/closersList" ? "waves-effect active" : "waves-effect"}`} onClick={() => setActiveLink( activeLink == 1 ? 0 : 1)}> */}
                                {/* <i className="mdi  mdi-account"></i> <span>  </span> */}
                                <i className="fa-solid fa-layer-group"></i><span> Tutorial </span>
                            </Link>

                            {activeLinkTutorials == 1 &&
                                <ul className="submenu">
                                <li>
                                    <Link to='/category' className={location.pathname.match('/category') ? "waves-effect subMenuActive" : "waves-effect"} >
                                    <i className="fa-solid fa-table-list"></i> <span> Category </span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/settertutorial' className={location.pathname.match('/settertutorial') ? "waves-effect subMenuActive" : "waves-effect"} >
                                        <i className="mdi mdi-account"></i> <span> Manage Setter Tutorials </span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/closertutorial' className={location.pathname.match('/closertutorial') ? "waves-effect subMenuActive" : "waves-effect"} >
                                        <i className="mdi mdi-account"></i> <span> Manage Closer Tutorials </span>
                                    </Link>
                                </li>
                                </ul>
                            }
                        </li>

                        <li>
                            <Link to='/lead-review-list' className={`${location.pathname === "/lead-review-list" ? "waves-effect active" : "waves-effect"}`} onClick={()=>{setActiveLink(0);setActiveLinkTask(0);setActiveLink1(0);setActiveLinkSupport(0);setActiveLinkTutorials(0);setActiveLinkSetting(0);}} >
                                <i className="mdi mdi-star"></i> <span> Lead Reviews </span>
                            </Link>
                        </li>

                        <li>    
                            <Link to='/earning-list' className={`${location.pathname === "/earning-list" || location.pathname.match('/lead-details')? "waves-effect active" : "waves-effect"}`} onClick={()=>{setActiveLink(0);setActiveLinkTask(0);setActiveLink1(0);setActiveLinkSupport(0);setActiveLinkTutorials(0);setActiveLinkSetting(0);}} >
                                <i className="mdi mdi-currency-usd"></i> <span> Earning List </span>
                            </Link>
                        </li>

                        <li>
                            {location.pathname.match('/airexport') || location.pathname.match('/airexportdetail') ? "mm-active" : ""}

                            <Link role="button" className={location.pathname.match('/commission') ? "waves-effect active" : "waves-effect"} onClick={() => {setActiveLink(0);setActiveLinkTask(0);setActiveLink1(0);setActiveLinkSupport(0);setActiveLinkTutorials(0);setActiveLinkSetting(activeLinkSetting == 1 ? 0 : 1);}}>
                            {/* <Link role="button" className={`${location.pathname == "/settersList" || location.pathname == "/closersList" ? "waves-effect active" : "waves-effect"}`} onClick={() => setActiveLink( activeLink == 1 ? 0 : 1)}> */}
                                {/* <i className="mdi  mdi-account"></i> <span>  </span> */}
                                <i className="mdi mdi-settings"></i><span> Setting </span>
                            </Link>

                            {activeLinkSetting == 1 &&
                                <ul className="submenu">
                                    <li>
                                        <Link to='/commission' className={location.pathname.match('/commission') ? "waves-effect subMenuActive" : "waves-effect"} >
                                        {location.pathname.match('/commission') ? 
                                            <img src={process.env.PUBLIC_URL + "/assets/images/icons8-percentage.gif"} width="18px" height="18px" alt="commission" className="rounded-circle" /> 
                                        :
                                            <img src={process.env.PUBLIC_URL + "/assets/images/icons8-percentage.gif"} width="18px" height="18px" alt="commission" className="rounded-circle" />  
                                        }
                                        <span> Commission </span>
                                        </Link>
                                    </li>
                                    {/* <li>
                                        <Link to='/settertutorial' className={location.pathname.match('/settertutorial') ? "waves-effect subMenuActive" : "waves-effect"} >
                                            <i className="mdi mdi-account"></i> <span> Manage Setter Tutorials </span>
                                        </Link>
                                    </li> */}
                                </ul>
                            }
                        </li>

                        
                    </ul>
                </div>
                <div className="clearfix"></div>
            </div>

        </div>
    );
}

export default Sidebar;